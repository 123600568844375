import React from "react"
import {
  Wrapper,
  Table,
  HeadingRow,
  Heading,
  Group,
  Item,
  Content,
  ItemHeading,
  Subheading,
  IconWrapper,
  CtaRow,
  Tooltip,
} from "../styles/DesktopTable.styled"
import tooltip from "../assets/Tooltip.svg"
import { CTA, CTABorder } from "../styles/UseCaseComponent.styled"
import { defaultResolveRichText } from "../../../utils/resolve-rich-text/defaultResolveRichText"

const DesktopTable = ({ component }) => {
  const groupSize = component?.columns.length

  return (
    <Wrapper groupSize={groupSize}>
      <HeadingRow groupSize={groupSize}>
        {component?.columns?.map((group, headingIdx) => (
          <Heading className={`heading-${headingIdx}`}>
            {group?.heading}
          </Heading>
        ))}
      </HeadingRow>
      <Table groupSize={groupSize}>
        {component?.columns?.map((group, groupIdx) => (
          <Group className={`group-${groupIdx}`} groupSize={groupSize}>
            {group?.items?.map((item, itemIdx) => (
              <Item className="item" groupSize={groupSize}>
                {item?.heading && (
                  <ItemHeading className="heading">
                    {item?.heading}
                    {item?.tooltip && (
                      <Tooltip>
                        <img src={tooltip} />
                        <div class="tooltip-content">
                          <div>{item?.tooltip?.tooltip}</div>
                          <i></i>
                        </div>
                      </Tooltip>
                    )}
                  </ItemHeading>
                )}
                <Content>
                  {item?.icon && (
                    <IconWrapper>
                      <img src={item?.icon?.url} />
                    </IconWrapper>
                  )}
                  <Subheading className="subheading">
                    {defaultResolveRichText(item?.subheading)}
                  </Subheading>
                </Content>
              </Item>
            ))}
          </Group>
        ))}
      </Table>

      {component?.callToAction?.link && (
        <CtaRow groupSize={groupSize}>
          <div></div>
          <CTABorder className="cta-border">
            <CTA href={component?.callToAction?.link}>
              {component?.callToAction?.label}
            </CTA>
          </CTABorder>
          {groupSize === 3 && <div></div>}
        </CtaRow>
      )}
    </Wrapper>
  )
}

export default DesktopTable
